import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização em português para o moment
import { FileContext } from '../../context/files';
const TableList = ({ table, financial, update }) => {

    const { get, files,getQrCode, getMultiples,getLink,getManualLink} = useContext(FileContext)

    useEffect(() => {
        get()
    }, [update])

    useEffect(() => {
        get()
    }, [])



    return (

        <div className='container_startpn_table'>
            <table>

                <thead>
                    <tr>
                        <td>Sku</td>
                        <td>Categoria</td>
                        <td className='table_revision'>Revisão</td>

                    </tr>
                </thead>


                <tbody>
                    {files?.map(item => {

                        return (

                            <tr>
                                <td onClick={() => getQrCode(item.file_id)}> <input className='check_box_mobile' type='checkbox' onClick={() => {getMultiples(item.file_id, item.category_image, item.uri,item.sku)}} /> {item.sku}</td>
                                <td onClick={() => {getQrCode(item.file_id);getLink(item.video_uri);getManualLink(item.uri)}} data-toggle="modal" data-target="#options"   >
                                    <img src={item.category_image} style={{ width: "41px", height: "41px", objectFit: "cover" }} />
                                </td>
                                <td onClick={() => {getLink(item.video_uri);getManualLink(item.uri)}} data-toggle="modal" data-target="#options"   className='table_revision'>{item.revision}</td>
                                
                            </tr>

                        )
                    })}

                    



                </tbody>

            </table>
        </div>
    )
}

export default TableList